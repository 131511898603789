import "./App.css";
import React, { useMemo } from "react";
import "./font.css";
import "./index.css";
import ZumaDashboard from "./Pages/ZumaDashboard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Apps from "./Pages/Apps";
// import Register from "./Pages/Register";
// import { useMediaQuery } from "@mui/material";
// import { Typography } from "@mui/material";
// import ReLine from "./Componets/ReLine";
// import ReBar from "./Componets/ReBar";
import { AuthProvider } from "./Context/AuthContext";
// import MainNav from "./Componets/MainNav";
// import Test from "./Pages/Test";
import Classic from "./Pages/Classic";
import Challenge from "./Pages/Challenge";
import Profile from "./Pages/Profile";
import Affiliate from "./Pages/Affiliate";
import Terms from "./Pages/Terms";
// import {
//   Menu,
//   Payout,
//   ManageQuizzes,
//   ManageVideos,
//   ProgramActivities,
//   ProgramBenefits,
//   PayoutDetails,
// } from "./Pages";
import PaymentSuccessful from "./Pages/PaymentSuccessful";
import axios from "./axios";
import { AccProvider } from "./Context/OtherContext";

function App() {
  // const [searchParams] = useSearchParams();
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  // let navigate = useNavigate();
  React.useEffect(() => {
    // Cookies.set("access_token", null);
    // Cookies.set("refresh_token", null);
    var token = params.get("token");
    var refresh = params.get("refresh_token");
    if (token) {
      // Cookies.set("access_token", token)
      sessionStorage.setItem("access_token", token);
    }

    if (refresh) {
      // Cookies.set("refresh_token", refresh)
      sessionStorage.setItem("refresh_token", refresh);
    }

    if (token !== null) {
      //console.log("token is not null");
      // return navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (sessionStorage.getItem("access_token")) {
      axios.get("dvas/").then((res) => {
        console.log(res);
        if (res.data[0]?.id) {
          sessionStorage.setItem("bank_name", res.data[0].bank_name);
          sessionStorage.setItem("account_name", res.data[0].account_name);
          sessionStorage.setItem("account_number", res.data[0].account_number);
        } else {
          axios.get("/profile/").then((res) => {
            console.log(res.data);
            sessionStorage.setItem("phone", res.data.user.phone);
            axios
              .post(
                "https://api.paystack.co/customer",
                {
                  email: res.data.user.email,
                  first_name: res.data.user.firstname,
                  last_name: res.data.user.lastname,
                },
                {
                  headers: {
                    Authorization:
                      "Bearer sk_live_5410af837186a63a497ee7814ab3f03fa102ad49",
                    "Content-Type": "application/json",
                    accept: "application/json",
                  },
                }
              )
              .then((res) => {
                console.log(res.data);
                axios
                  .post(
                    "https://api.paystack.co/dedicated_account",
                    {
                      customer: res.data.data.customer_code,
                      preferred_bank: "titan-paystack",
                      phone: sessionStorage.getItem("phone"),
                    },
                    {
                      headers: {
                        Authorization:
                          "Bearer sk_live_5410af837186a63a497ee7814ab3f03fa102ad49",
                        "Content-Type": "application/json",
                        accept: "application/json",
                      },
                    }
                  )
                  .then((res) => {
                    console.log(res.data);
                    sessionStorage.setItem(
                      "bank_name",
                      res.data.data.bank.name
                    );
                    sessionStorage.setItem(
                      "account_name",
                      res.data.data.account_name
                    );
                    sessionStorage.setItem(
                      "account_number",
                      res.data.data.account_number
                    );
                    axios
                      .post("dvas/", {
                        account_name: res.data.data.account_name,
                        account_number: res.data.data.account_number,
                        bank_name: res.data.data.bank.name,
                      })
                      .then((res) => {
                        console.log(res);
                      });
                  });
              });
            // setFirstname(res.data.user.firstname);
            // setLastname(res.data.user.lastname);
            // setGender(res.data.gender);
            // setDob(res.data.dob);
          });
        }
      });
    }
  }, []);
  return (
    <div className="App">
      <Router>
        <AccProvider>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/dashboards" element={<ZumaDashboard />} />
              <Route path="/apps" element={<Apps />} />
              <Route path="/login" element={<Login />} />
              {/* <Route path="/register" element={<Register />} /> */}
              {/* <Route path="/linetest" element={<ReBar />} /> */}
              {/* <Route path="/test" element={<Test />} /> */}
              <Route path="/terms" element={<Terms />} />
              <Route path="/classic" element={<Classic />} />
              <Route path="/challenge" element={<Challenge />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/affiliate" element={<Affiliate />} />
              {/* <Route path="/v1/secure/2022/ftadmin" element={<Menu />} />
            <Route path="/payouts" element={<Payout />} />
            <Route path="/payout-details" element={<PayoutDetails />} />
            <Route path="/manage-quizzes" element={<ManageQuizzes />} />
            <Route path="/manage-videos" element={<ManageVideos />} />
            <Route path="/program-activities" element={<ProgramActivities />} />
            <Route path="/program-benefits" element={<ProgramBenefits />} /> */}
              <Route
                path="/payment-successful"
                element={<PaymentSuccessful />}
              />
            </Routes>
          </AuthProvider>
        </AccProvider>
      </Router>
    </div>
  );
}

export default App;
